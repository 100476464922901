import { useNavigate } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";

import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
  SheetClose,
  Sheet,
} from "../components/ui/sheet";
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "../components/ui/table";

import {
  AdminRole,
  useAddUserMutation,
  useGetLogHistoryOfOrganizationQuery,
  useGetOrganizationQuery,
  useGetUsersOfOrganizationQuery,
} from "../generated/graphql";
import { useEffect, useState } from "react";
import { Label } from "../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../components/ui/pagination";
import { useToast } from "../components/ui/use-toast";
import { Toaster } from "../components/ui/toaster";
import { formatDate } from "../lib/formatDate";

export function Organization() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [logPage, setLogPage] = useState(1);

  const [username, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(AdminRole.User);

  const [{ data: addUserData, error: addUserError }, addUser] =
    useAddUserMutation();

  const [{ data: organizationData, error: organizationError }] =
    useGetOrganizationQuery({
      variables: {},
    });
  const [{ data: usersData, error: userError }, refetchUsers] =
    useGetUsersOfOrganizationQuery({
      variables: {},
    });
  const [{ data: logsData, error: logsError }] =
    useGetLogHistoryOfOrganizationQuery({
      variables: { page: logPage },
    });

  const organization = organizationData?.getOrganization;
  const users = usersData?.getUsersOfOrganization;
  const logs = logsData?.getLogHistoryOfOrganization;

  // toast errors
  useEffect(() => {
    if (userError) {
      toast({
        title: "Error",
        description: userError.message,
        variant: "destructive",
      });
    }

    if (organizationError) {
      toast({
        title: "Error",
        description: organizationError.message,
        variant: "destructive",
      });
    }

    if (logsError) {
      toast({
        title: "Error",
        description: logsError.message,
        variant: "destructive",
      });
    }

    if (addUserError) {
      toast({
        title: "Error",
        description: addUserError.message,
        variant: "destructive",
      });
    }
  }, [userError, toast, organizationError, logsError, addUserError]);

  useEffect(() => {
    if (addUserData) {
      toast({
        title: "Success",
        description: "User added successfully",
        variant: "default",
      });
      refetchUsers();
    }
  }, [addUserData, refetchUsers, toast]);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Organization</CardTitle>
            {/* <DollarSign className="h-4 w-4 text-muted-foreground" /> */}
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold"></div>

            <p className="text-xs text-muted-foreground">
              Name: {organization?.name}
            </p>
          </CardContent>
        </Card>

        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center justify-between">
            <div className="grid gap-2">
              <CardTitle>{organization?.name}: Users</CardTitle>
            </div>
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline" className="bg-black text-white">
                  Add User
                </Button>
              </SheetTrigger>
              <SheetContent side="bottom">
                <SheetHeader>
                  <SheetTitle>Add User</SheetTitle>
                </SheetHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="username" className="text-right">
                      Username
                    </Label>
                    <Input
                      id="username"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => {
                        setUser(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="email" className="text-right">
                      Email
                    </Label>
                    <Input
                      id="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="Role" className="text-right">
                      Role
                    </Label>
                    <Select
                      value={role}
                      onValueChange={(v: AdminRole) => {
                        setRole(v);
                      }}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={AdminRole.SuperAdmin}>
                          Super Admin
                        </SelectItem>
                        <SelectItem value={AdminRole.Admin}>Admin</SelectItem>
                        <SelectItem value={AdminRole.User}>User</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <SheetFooter>
                  <SheetClose asChild>
                    <Button
                      type="submit"
                      disabled={!username || !email || !role}
                      onClick={async () => {
                        await addUser({
                          username,
                          email,
                          role,
                        });
                        setEmail("");
                        setUser("");
                        setRole(AdminRole.User);
                      }}
                    >
                      Save changes
                    </Button>
                  </SheetClose>
                </SheetFooter>
              </SheetContent>
            </Sheet>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Username</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Role</TableHead>
                  <TableHead>Is Active</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {users?.map((user, idx) => (
                  <TableRow
                    key={idx}
                    onClick={() => {
                      navigate(`/user/${user.userId}`);
                    }}
                  >
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{`${user.isActive}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center">
            <div className="grid gap-2">
              <CardTitle>{organization?.name}: Logs</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>id</TableHead>
                  <TableHead>Username</TableHead>
                  <TableHead>Action</TableHead>
                  <TableHead>Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {logs?.map((log, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.username}</TableCell>
                    <TableCell>{log.action}</TableCell>
                    <TableCell>{formatDate(log.date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      setLogPage(logPage - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{logPage}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      setLogPage(logPage + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>
      </main>
      <Toaster />
    </div>
  );
}
