import * as React from "react";

import { ArrowLeft, DollarSign, Users } from "lucide-react";
import { Button } from "../components/ui/button";

import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { useGetDistributionQuery } from "../generated/graphql";
import { useEffect } from "react";
import { Toaster } from "../components/ui/toaster";
import { useToast } from "../components/ui/use-toast";
import { formatNumber } from "../lib/formatNumbers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";

export function Distribution() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { id } = useParams();

  const [{ data, error }] = useGetDistributionQuery({
    variables: {
      id: id ? parseInt(id) : null || 0,
    },
    pause: !id,
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  }, [error, toast]);

  const distribution = data?.getDistribution;

  return (
    <div className="flex min-h-screen w-full flex-col">
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <Button variant="outline" size="icon" onClick={() => navigate("/")}>
            <ArrowLeft className="h-4 w-4" />
          </Button>
        </div>
      </header>
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        {distribution != null && (
          <>
            <div className="grid gap-2 md:grid-cols-1 md:gap-8 lg:grid-cols-2">
              {/* <div className="grid gap-2"> */}
              <div>
                <div className="text-lg">ID</div>
                <h1 className="text-2xl font-semibold">D{distribution.id}</h1>
              </div>
              <div>
                <div className="text-lg">Status</div>
                <div className="flex items-center">
                  <div
                    style={{
                      height: 20,
                      width: 20,
                      borderRadius: "50%",
                      marginRight: 10,
                      background:
                        distribution.status === "CANCELLED"
                          ? "grey"
                          : distribution.status === "PROCESSED"
                          ? "green"
                          : "yellow",
                    }}
                  />
                  <div className="text-2xl font-semibold">
                    {distribution.status}
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Payouts
                  </CardTitle>
                  <Users className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">
                    {distribution.transactions.length}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Distributed
                  </CardTitle>
                  <CardTitle className="text-sm font-medium">
                    Gas & Processing fee
                  </CardTitle>
                  <CardTitle className="text-sm font-medium">
                    Total Amount
                  </CardTitle>
                  <DollarSign className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="flex justify-between pb-2">
                    <div className="text-2xl font-bold">
                      ${formatNumber(distribution.amount.toString())}
                    </div>
                    <div className="text-lg font-bold">
                      ${distribution.gas ? distribution.gas.toString() : "-"}
                    </div>
                    <div className="text-lg font-bold">
                      $
                      {distribution.totalAmount
                        ? formatNumber(distribution.totalAmount.toString())
                        : "-"}
                    </div>
                    <div></div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Currency
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-lg font-bold">{distribution.mint}</div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">Info</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex justify-between pb-2">
                    <div>
                      <div className="text-lg font-bold">
                        Created By: {distribution.createdBy}
                      </div>
                      <div className="text-lg font-bold">
                        Approved By:{" "}
                        {distribution.approvedBy || "Not Approved Yet"}
                      </div>
                    </div>
                    <div>
                      <div className="text-lg font-bold">
                        Created At:{" "}
                        {new Date(distribution.createdAt).toLocaleDateString()}
                      </div>
                      <div className="text-lg font-bold">
                        Processed At:{" "}
                        {distribution.processedAt
                          ? new Date(
                              distribution.processedAt
                            ).toLocaleDateString()
                          : "Not Processed"}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {distribution.txHash && (
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Transaction Hash
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div
                      className="text-lg font-bold cursor-pointer"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_ETH_EXPLORER_URL}${distribution.txHash}`,
                          "_blank"
                        );
                      }}
                    >
                      {distribution.txHash.slice(0, 10)}...
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between">
                <div className="grid gap-2">
                  <CardTitle> Detailed Distribution</CardTitle>
                </div>
                {distribution.processedAt != null && (
                  <Button
                    variant="outline"
                    size="icon"
                    className="bg-black text-white px-6"
                    onClick={() => {
                      const csv = distribution.transactions.map((t) => {
                        return {
                          referenceID: "D" + distribution.id,
                          invoiceId: t.invoiceId,
                          name: t.toName,
                          wallet: t.to,
                          amount: formatNumber(t.amount),
                          currency: distribution.mint,
                          datePaid: new Date(
                            distribution.processedAt!
                          ).toLocaleDateString(),
                          txHash: distribution.txHash,
                        };
                      });

                      // Parse the data into CSV
                      const parsed = csv.map((row) =>
                        Object.values(row)
                          .map((val) => `"${val}"`)
                          .join(",")
                      );

                      parsed.unshift(
                        Object.keys(csv[0])
                          .map((key) => `"${key}"`)
                          .join(",")
                      );

                      const csvData = new Blob([parsed.join("\n")], {
                        type: "text/csv;charset=utf-8",
                      });
                      const csvUrl = URL.createObjectURL(csvData);
                      const a = document.createElement("a");
                      a.href = csvUrl;
                      a.download = `distribution-${distribution.id}.csv`;
                      a.click();
                      URL.revokeObjectURL(csvUrl);
                    }}
                  >
                    CSV
                  </Button>
                )}
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Invoice ID</TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead>Wallet</TableHead>
                      <TableHead>Amount</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {distribution.transactions?.map((d, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{d.invoiceId}</TableCell>
                        <TableCell>{d.toName}</TableCell>
                        <TableCell>{d.to}</TableCell>
                        <TableCell>${formatNumber(d.amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </>
        )}
      </main>
      <Toaster />
    </div>
  );
}
