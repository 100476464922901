import { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import {
  useGetUserQuery,
  useRequestTwoFactorAuthMutation,
  useSetupTwoFactorAuthMutation,
  useUpdateEmailMutation,
  useUpdateUsernameMutation,
} from "../generated/graphql";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../components/ui/input-otp";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import QRCode from "react-qr-code";
import { useToast } from "../components/ui/use-toast";
import { Toaster } from "../components/ui/toaster";

export function Settings() {
  const { toast } = useToast();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [
    { data: updateUsernameData, error: updateUsernameError },
    updateUsername,
  ] = useUpdateUsernameMutation();
  const [{ data: updateEmailData, error: updateEmailError }, updateEmail] =
    useUpdateEmailMutation();
  const [
    { data: requestTwoAuthData, error: requestTwoAuthError },
    requestTwoAuth,
  ] = useRequestTwoFactorAuthMutation();
  const [{ data: setupTwoAuthData, error: setupTwoAuthError }, setupTwoAuth] =
    useSetupTwoFactorAuthMutation();
  const [{ data: userData, error: userError }, refetchUser] = useGetUserQuery();

  const user = userData?.getUser;

  useEffect(() => {
    setUsername(user?.username ?? "");
    setEmail(user?.email ?? "");
  }, [user]);

  useEffect(() => {
    if (updateUsernameData) {
      toast({
        title: "Success",
        description: "Username updated successfully",
      });
      refetchUser();
    }

    if (updateUsernameError) {
      toast({
        title: "Error",
        description: updateUsernameError.message,
        variant: "destructive",
      });
    }

    if (updateEmailData) {
      toast({
        title: "Success",
        description: "Email updated successfully",
      });
      refetchUser();
    }

    if (updateEmailError) {
      toast({
        title: "Error",
        description: updateEmailError.message,
        variant: "destructive",
      });
    }

    if (userError) {
      toast({
        title: "Error",
        description: userError.message,
        variant: "destructive",
      });
    }
  }, [
    refetchUser,
    toast,
    updateEmailData,
    updateEmailError,
    updateUsernameData,
    updateUsernameError,
    userError,
  ]);

  useEffect(() => {
    if (setupTwoAuthData) {
      toast({
        title: "Success",
        description: "MFA setup successfully",
      });
    }

    if (setupTwoAuthError) {
      toast({
        title: "Error",
        description: setupTwoAuthError.message,
        variant: "destructive",
      });
    }

    if (requestTwoAuthError) {
      toast({
        title: "Error",
        description: requestTwoAuthError.message,
        variant: "destructive",
      });
    }
  }, [requestTwoAuthError, setupTwoAuthData, setupTwoAuthError, toast]);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <Card x-chunk="A card showing the total revenue in USD and the percentage difference from last month.">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">User</CardTitle>
            {user?.has2fa && (
              <CardTitle className="text-sm font-medium text-green-600">
                MFA enabled
              </CardTitle>
            )}
          </CardHeader>
          <CardContent>
            <p className="text-xs text-muted-foreground">{user?.username}</p>
            <p className="text-xs text-muted-foreground">{user?.email}</p>
            <br />
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline">Edit</Button>
              </SheetTrigger>
              <SheetContent side="bottom">
                <SheetHeader>
                  <SheetTitle>Edit User</SheetTitle>
                </SheetHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                      Name
                    </Label>
                    <Input
                      id="name"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="email" className="text-right">
                      Email
                    </Label>
                    <Input
                      id="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="col-span-3"
                    />
                  </div>
                </div>
                <SheetFooter>
                  <SheetClose asChild>
                    <Button
                      type="submit"
                      onClick={async () => {
                        if (username !== user?.username) {
                          await updateUsername({ username });
                        }

                        if (email !== user?.email) {
                          await updateEmail({ email });
                        }
                      }}
                    >
                      Save changes
                    </Button>
                  </SheetClose>
                </SheetFooter>
              </SheetContent>
            </Sheet>

            {user?.has2fa !== true && (
              <Sheet>
                <SheetTrigger asChild>
                  <Button
                    variant="outline"
                    onClick={async () => {
                      await requestTwoAuth({});
                    }}
                  >
                    Setup MFA
                  </Button>
                </SheetTrigger>
                <SheetContent side="bottom">
                  <SheetHeader>
                    <SheetTitle>Setup MFA</SheetTitle>
                  </SheetHeader>
                  <div className="grid gap-4 py-4">
                    {requestTwoAuthData?.requestTwoFactorAuth && (
                      <QRCode value={requestTwoAuthData.requestTwoFactorAuth} />
                    )}
                    {/* <img src={requestTwoAuthData?.requestTwoFactorAuth} /> */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <InputOTP
                        maxLength={6}
                        pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                        onComplete={async (value) => {
                          await setupTwoAuth({ secret: value });
                        }}
                      >
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </div>
                  </div>
                </SheetContent>
              </Sheet>
            )}
          </CardContent>
        </Card>
      </main>
      <Toaster />
    </div>
  );
}
