import {
  AdminRole,
  useGetLogHistoryOfUserQuery,
  useGetOtherUserQuery,
  useResetPasswordMutation,
  useToggleUserStatusMutation,
  useUpdateUserRoleMutation,
} from "../generated/graphql";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";

import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
} from "../components/ui/table";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../components/ui/pagination";
import { useToast } from "../components/ui/use-toast";
import { Toaster } from "../components/ui/toaster";

export function User() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { toast } = useToast();
  const userId = id ?? "";
  const [page, setPage] = useState(1);
  const [role, setRole] = useState(AdminRole.User);

  const [{ data: userData, error: userError }, refetchUser] =
    useGetOtherUserQuery({
      variables: { userId: userId },
      pause: !userId,
    });

  const [{ data: logsData, error: logsError }] = useGetLogHistoryOfUserQuery({
    variables: { userId, page: page },
    pause: !userId,
  });

  const [
    { data: updateUserRoleData, error: updateUserRoleError },
    updateUserRole,
  ] = useUpdateUserRoleMutation();
  const [{ data: toggleUserData, error: toggleUserError }, toggleUser] =
    useToggleUserStatusMutation();
  const [
    { data: resetPasswordData, error: resetPasswordError },
    resetPassword,
  ] = useResetPasswordMutation();

  useEffect(() => {
    if (!userId) {
      navigate("/");
    }
  }, [navigate, userId]);

  useEffect(() => {
    if (userData?.getOtherUser) {
      setRole(userData.getOtherUser.role);
    }
  }, [userData]);

  useEffect(() => {
    if (updateUserRoleData) {
      toast({
        title: "Success",
        description: "User role updated successfully",
      });
      refetchUser();
    }

    if (updateUserRoleError) {
      toast({
        title: "Error",
        description: updateUserRoleError.message,
        variant: "destructive",
      });
    }

    if (toggleUserData) {
      toast({
        title: "Success",
        description: "User status updated successfully",
      });
      refetchUser();
    }

    if (toggleUserError) {
      toast({
        title: "Error",
        description: toggleUserError.message,
        variant: "destructive",
      });
    }

    if (resetPasswordData) {
      toast({
        title: "Success",
        description: "Password reset successfully",
      });
    }

    if (resetPasswordError) {
      toast({
        title: "Error",
        description: resetPasswordError.message,
        variant: "destructive",
      });
    }

    if (userError) {
      toast({
        title: "Error",
        description: userError.message,
        variant: "destructive",
      });
    }

    if (logsError) {
      toast({
        title: "Error",
        description: logsError.message,
        variant: "destructive",
      });
    }
  }, [
    toast,
    updateUserRoleError,
    updateUserRoleData,
    toggleUserError,
    toggleUserData,
    resetPasswordData,
    resetPasswordError,
    userError,
    logsError,
    refetchUser,
  ]);

  const logs = logsData?.getLogHistoryOfUser;
  const user = userData?.getOtherUser;

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Navbar />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">User</CardTitle>
            {/* <DollarSign className="h-4 w-4 text-muted-foreground" /> */}
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline">Edit</Button>
              </SheetTrigger>
              <SheetContent side="bottom">
                <SheetHeader>
                  <SheetTitle>Edit User</SheetTitle>
                </SheetHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="Role" className="text-right">
                      Role
                    </Label>
                    <Select
                      onValueChange={(v: AdminRole) => {
                        setRole(v);
                      }}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={AdminRole.SuperAdmin}>
                          Super Admin
                        </SelectItem>
                        <SelectItem value={AdminRole.Admin}>Admin</SelectItem>
                        <SelectItem value={AdminRole.User}>User</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <SheetFooter>
                  <SheetClose asChild>
                    <Button
                      type="submit"
                      disabled={role === user?.role}
                      onClick={async () => {
                        if (!id) return;

                        if (role !== user?.role) {
                          await updateUserRole({
                            userId: id,
                            role: role,
                          });
                        }
                      }}
                    >
                      Save changes
                    </Button>
                  </SheetClose>
                  <SheetClose asChild>
                    <Button
                      variant={"outline"}
                      onClick={async () => {
                        await toggleUser({ userId: id! });
                      }}
                    >
                      {user?.isActive ? "Deactivate" : "Activate"}
                    </Button>
                  </SheetClose>
                  <SheetClose asChild>
                    <Button
                      onClick={async () => {
                        await resetPassword({ userId: id! });
                      }}
                    >
                      Reset Password
                    </Button>
                  </SheetClose>
                </SheetFooter>
              </SheetContent>
            </Sheet>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold"></div>
            <p className="text-xs text-muted-foreground">
              Name: {user?.username}
            </p>
            <p className="text-xs text-muted-foreground">
              Email: {user?.email}
            </p>
            <p className="text-xs text-muted-foreground">Role: {user?.role}</p>
            <p className="text-xs text-muted-foreground">
              Is Active: {user?.isActive}
            </p>
          </CardContent>
        </Card>

        <Card className="xl:col-span-2">
          <CardHeader className="flex flex-row items-center">
            <div className="grid gap-2">
              <CardTitle>Logs</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>id</TableHead>
                  <TableHead>Action</TableHead>
                  <TableHead>Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {logs?.map((log, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.action}</TableCell>
                    <TableCell>{log.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() => {
                      setPage(page - 1);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink>{page}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </CardContent>
        </Card>
      </main>
      <Toaster />
    </div>
  );
}
