import * as React from "react";

import { ArrowLeft, DollarSign, Users } from "lucide-react";
import { Button } from "../components/ui/button";

import { useNavigate } from "react-router-dom";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  useGetOrgAndUserQuery,
  useGetOrganizationQuery,
  useValidateDistributionMutation,
} from "../generated/graphql";
import { useEffect, useState } from "react";
import { Toaster } from "../components/ui/toaster";
import { useToast } from "../components/ui/use-toast";
import { formatNumber } from "../lib/formatNumbers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";

const SHA3 = require("crypto-js/sha3");
interface Distribution {
  amount: string;
  wallet: string;
  invoiceId: string;
  toName: string;
}

const sha3 = (value: string) => {
  return SHA3(value, {
    outputLength: 256,
  }).toString();
};

const isAddress = (address: string) => {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    // Check if it has the basic requirements of an address
    return false;
  } else if (
    /^(0x)?[0-9a-f]{40}$/.test(address) ||
    /^(0x)?[0-9A-F]{40}$/.test(address)
  ) {
    // If it's all small caps or all all caps, return true
    return true;
  } else {
    // Otherwise check each case
    return isChecksumAddress(address);
  }
};

const isChecksumAddress = function (address: string) {
  // Check each case
  address = address.replace("0x", "");
  let addressHash = sha3(address.toLowerCase());

  for (let i = 0; i < 40; i++) {
    // The nth letter should be uppercase if the nth digit of casemap is 1
    if (
      (parseInt(addressHash[i], 16) > 7 &&
        address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 &&
        address[i].toLowerCase() !== address[i])
    ) {
      return false;
    }
  }
  return true;
};

export function UploadDistribution() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const fileRef = React.useRef<HTMLInputElement>(null);
  const [mintId, setMintId] = useState(0);
  const [csv, setCsv] = useState<File | null>(null);
  const [distribution, setDistribution] = useState<Distribution[]>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [fileName, setFileName] = useState("");
  const [{ data, error }, uploadDistribution] =
    useValidateDistributionMutation();
  const [errors, setErrors] = useState<Set<number>>(new Set());
  const [errorCount, setErrorCount] = useState(0);

  const [{ data: userData, error: userError }] = useGetOrgAndUserQuery({
    requestPolicy: "network-only",
  });

  const [{ data: organizationData }] = useGetOrganizationQuery();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const balances = organizationData?.getOrganization.balance ?? [];

  useEffect(() => {
    if (balances.length > 0) {
      setMintId(balances[0].mintId);
    }
  }, [balances]);

  useEffect(() => {
    if (csv) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (!csv.type.includes("text/csv")) {
          toast({
            title: "Error",
            description: "Invalid file type, must be a csv file",
            variant: "destructive",
          });
          return;
        }
        setErrors(new Set());
        setErrorCount(0);
        const text = e?.target?.result as string;
        const lines = text.split("\n");
        if (lines[0].includes("amount") || lines[0].includes("wallet")) {
          lines.shift();
        }
        let _totalAmount = 0;
        try {
          setDistribution(
            lines
              .map((line, idx) => {
                // skip empty lines
                if (line.trim() === "") {
                  return null;
                }
                const lineArr = line.split(",");
                if (lineArr.length !== 4) {
                  toast({
                    title: "Error",
                    description:
                      "Invalid csv format: " +
                      line +
                      " must be in the format: invoice id, to name, amount, wallet address",
                    variant: "destructive",
                  });
                  throw new Error("Invalid distribution");
                }
                if (isNaN(Number(lineArr[2].trim()))) {
                  setErrors((prev) => new Set(prev).add(idx));
                  setErrorCount((prev) => prev + 1);
                }

                const [invoiceId, toName, amount, wallet] = lineArr;
                if (
                  amount.trim() === "" ||
                  wallet.trim() === "" ||
                  invoiceId.trim() === "" ||
                  toName.trim() === ""
                ) {
                  setErrors((prev) => new Set(prev).add(idx));
                  if (amount.trim() === "") {
                    setErrorCount((prev) => prev + 1);
                  }
                  if (wallet.trim() === "") {
                    setErrorCount((prev) => prev + 1);
                  }
                  if (invoiceId.trim() === "") {
                    setErrorCount((prev) => prev + 1);
                  }
                  if (toName.trim() === "") {
                    setErrorCount((prev) => prev + 1);
                  }
                }

                if (!isAddress(wallet.trim())) {
                  setErrors((prev) => new Set(prev).add(idx));
                  setErrorCount((prev) => prev + 1);
                }
                let amountNum = 0;
                if (!Number.isNaN(Number(amount.trim()))) {
                  amountNum = Number(amount.trim()) * 10 ** 6;
                }

                _totalAmount += amountNum;
                return {
                  amount: amount.trim(),
                  wallet: wallet.trim(),
                  invoiceId: invoiceId.trim(),
                  toName: toName.trim(),
                };
              })
              .filter((x) => x !== null) as Distribution[]
          );
          setTotalAmount(_totalAmount);
        } catch (e) {}
      };
      reader.readAsText(csv);
    }
  }, [csv, toast]);

  useEffect(() => {
    if (data) {
      toast({
        title: "Success",
        description: "Distribution uploaded",
        variant: "default",
      });
      navigate("/");
    }
  }, [data, navigate, toast]);

  useEffect(() => {
    if (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }

    if (userError) {
      console.error(userError);
      toast({
        title: "Error",
        description: userError.message,
        variant: "destructive",
      });
    }
  }, [error, toast, userError]);

  const balance = userData?.getOrganization.balance;

  return (
    <div className="flex min-h-screen w-full flex-col">
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <Button variant="outline" size="icon" onClick={() => navigate("/")}>
            <ArrowLeft className="h-4 w-4" />
          </Button>
        </div>
      </header>
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div className="grid gap-2 md:grid-cols-1 md:gap-8 lg:grid-cols-2">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-lg font-large">
                Upload Distribution
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid w-full max-w-sm items-center gap-1.5">
                <Label htmlFor="csv" className="text-nowrap">
                  CSV that is formated: invoice id,to name,amount,wallet address
                </Label>
                <Input
                  id="csv"
                  type="file"
                  style={{ display: "none" }}
                  ref={fileRef}
                  accept=".csv"
                  onChange={(e) => {
                    console.log(e);

                    setCsv(e.target.files?.[0] || null);
                    setFileName(e.target.files?.[0]?.name || "");
                    e.target.value = "";
                  }}
                />
                <div className="flex items-center">
                  <Button
                    onClick={() => {
                      fileRef.current?.click();
                    }}
                  >
                    Upload File
                  </Button>
                  <div
                    style={{
                      marginLeft: 10,
                      padding: 8,
                      borderWidth: 1,
                      borderRadius: 4,
                    }}
                  >
                    {fileName || "No file selected"}
                  </div>
                </div>

                <Select
                  value={mintId.toString()}
                  onValueChange={(v: string) => {
                    setMintId(parseInt(v));
                  }}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Balance" />
                  </SelectTrigger>
                  <SelectContent>
                    {balances.map((balance, idx) => (
                      <SelectItem key={idx} value={balance.mintId.toString()}>
                        {balance.mint}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Balance</CardTitle>
              <DollarSign className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              {balance?.map((b, idx) => (
                <div key={idx} className="text-2xl font-bold">
                  {b.mint} ${formatNumber(b.credit ?? "0")}
                </div>
              ))}
              <p className="text-xs text-muted-foreground">Current balance</p>
            </CardContent>
          </Card>
        </div>

        {distribution.length > 0 && (
          <>
            <div className="grid gap-2 md:grid-cols-1 md:gap-8 lg:grid-cols-2">
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Payouts
                  </CardTitle>
                  <Users className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">
                    {distribution.length}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Amount
                  </CardTitle>
                  <DollarSign className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">
                    ${formatNumber(totalAmount.toString())}
                  </div>
                </CardContent>
              </Card>
            </div>

            {errorCount > 0 && (
              <div className="grid gap-2 md:grid-cols-1 md:gap-8 lg:grid-cols-2">
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Lines with Errors
                    </CardTitle>
                    <Users className="h-4 w-4 text-muted-foreground" />
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">{errors.size}</div>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Total Errors found
                    </CardTitle>
                    <Users className="h-4 w-4 text-muted-foreground" />
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">{errorCount}</div>
                  </CardContent>
                </Card>
              </div>
            )}

            <Card>
              <CardHeader className="flex flex-row items-center">
                <div className="grid gap-2">
                  <CardTitle> Detailed Distribution</CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Invoice ID</TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead>Amount</TableHead>
                      <TableHead>Wallet</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {distribution?.map((d, idx) => (
                      <TableRow
                        className={errors.has(idx) ? "bg-red-100" : ""}
                        key={idx}
                      >
                        <TableCell>{d.invoiceId}</TableCell>
                        <TableCell>{d.toName}</TableCell>
                        <TableCell>${d.amount}</TableCell>
                        <TableCell>{d.wallet}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div style={{ paddingTop: 20, textAlign: "end" }}>
                  {distribution.length > 0 && (
                    <Button
                      disabled={errorCount > 0}
                      onClick={async () => {
                        await uploadDistribution({
                          mint: mintId,
                          distribution: distribution.map((dist) => ({
                            amount: Math.round(
                              Number(dist.amount) * 10 ** 6
                            ).toString(),
                            toName: dist.toName,
                            invoiceId: dist.invoiceId,
                            to: dist.wallet,
                          })),
                        });
                      }}
                    >
                      Upload
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </main>
      <Toaster />
    </div>
  );
}
