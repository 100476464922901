import { GraphQLResolveInfo } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum AdminRole {
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  User = 'USER'
}

export type Balance = {
  __typename?: 'Balance';
  credit: Scalars['String']['output'];
  mint: Scalars['String']['output'];
  mintId: Scalars['Int']['output'];
  onHold: Scalars['String']['output'];
};

export type DetailedDistribution = {
  __typename?: 'DetailedDistribution';
  amount: Scalars['String']['output'];
  approvedBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  gas?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  mint: Scalars['String']['output'];
  processedAt?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  totalAmount?: Maybe<Scalars['String']['output']>;
  transactions: Array<DistributionTransaction>;
  txHash?: Maybe<Scalars['String']['output']>;
};

export type Distribution = {
  __typename?: 'Distribution';
  creater: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  totalAmount: Scalars['String']['output'];
  transactions: Array<DistributionTransaction>;
};

export type DistributionTransaction = {
  __typename?: 'DistributionTransaction';
  amount: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  to: Scalars['String']['output'];
  toName: Scalars['String']['output'];
};

export type DistributionTransactionInput = {
  amount: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  to: Scalars['String']['input'];
  toName: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addUser: User;
  approveDistribution: Scalars['Int']['output'];
  logout: Scalars['Boolean']['output'];
  rejectDistribution: Scalars['Int']['output'];
  requestTwoFactorAuth: Scalars['String']['output'];
  resetMyPassword: User;
  resetPassword: User;
  setupTwoFactorAuth: User;
  toggleUserStatus: User;
  updateEmail: User;
  updatePassword: User;
  updateUserRole: User;
  updateUsername: User;
  validateDistribution: Distribution;
};


export type MutationAddUserArgs = {
  email: Scalars['String']['input'];
  role: AdminRole;
  username: Scalars['String']['input'];
};


export type MutationApproveDistributionArgs = {
  id: Scalars['Int']['input'];
  secret: Scalars['String']['input'];
};


export type MutationRejectDistributionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationResetPasswordArgs = {
  userId: Scalars['String']['input'];
};


export type MutationSetupTwoFactorAuthArgs = {
  secret: Scalars['String']['input'];
};


export type MutationToggleUserStatusArgs = {
  userId: Scalars['String']['input'];
};


export type MutationUpdateEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationUpdateUserRoleArgs = {
  role: AdminRole;
  userId: Scalars['String']['input'];
};


export type MutationUpdateUsernameArgs = {
  username: Scalars['String']['input'];
};


export type MutationValidateDistributionArgs = {
  distribution: Array<DistributionTransactionInput>;
  mint: Scalars['Int']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  balance: Array<Balance>;
  btcWalletAddress?: Maybe<Scalars['String']['output']>;
  ethWalletAddress: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  getDistribution: DetailedDistribution;
  getDistributionsToApprove: Array<Distribution>;
  getLogHistoryOfOrganization: Array<UserLog>;
  getLogHistoryOfUser: Array<UserLog>;
  getNonce: Scalars['Int']['output'];
  getOrganization: Organization;
  getOtherUser: User;
  getTransactionsHistory: Array<Transaction>;
  getUser: User;
  getUsersOfOrganization: Array<User>;
};


export type QueryGetDistributionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetLogHistoryOfOrganizationArgs = {
  page: Scalars['Int']['input'];
};


export type QueryGetLogHistoryOfUserArgs = {
  page: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};


export type QueryGetOtherUserArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetTransactionsHistoryArgs = {
  page: Scalars['Int']['input'];
};

export type Transaction = {
  __typename?: 'Transaction';
  credit: Scalars['String']['output'];
  creditDelta: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isProcessed: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  txHash?: Maybe<Scalars['String']['output']>;
  type: TransactionType;
};

export enum TransactionType {
  Distribution = 'DISTRIBUTION',
  Topup = 'TOPUP'
}

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  has2fa: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  role: AdminRole;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UserLog = {
  __typename?: 'UserLog';
  action: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  organizationName: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type GetNonceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNonceQuery = { __typename?: 'Query', getNonce: number };

export type GetOrgAndUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgAndUserQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, ethWalletAddress: string, btcWalletAddress?: string | null, balance: Array<{ __typename?: 'Balance', credit: string, onHold: string, mint: string, mintId: number }> }, getUser: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: AdminRole, has2fa: boolean } };

export type GetOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, ethWalletAddress: string, btcWalletAddress?: string | null, balance: Array<{ __typename?: 'Balance', credit: string, onHold: string, mint: string, mintId: number }> } };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, has2fa: boolean, role: AdminRole } };

export type GetOtherUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetOtherUserQuery = { __typename?: 'Query', getOtherUser: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: AdminRole } };

export type GetTransactionsHistoryQueryVariables = Exact<{
  page: Scalars['Int']['input'];
}>;


export type GetTransactionsHistoryQuery = { __typename?: 'Query', getTransactionsHistory: Array<{ __typename?: 'Transaction', id: number, type: TransactionType, creditDelta: string, credit: string, date: string, isProcessed: boolean, txHash?: string | null, status: string }> };

export type GetDistributionsToApproveQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDistributionsToApproveQuery = { __typename?: 'Query', getDistributionsToApprove: Array<{ __typename?: 'Distribution', id: number, totalAmount: string, creater: string, transactions: Array<{ __typename?: 'DistributionTransaction', to: string, amount: string }> }> };

export type GetDistributionQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetDistributionQuery = { __typename?: 'Query', getDistribution: { __typename?: 'DetailedDistribution', id: number, amount: string, gas?: string | null, totalAmount?: string | null, createdBy: string, approvedBy?: string | null, createdAt: string, processedAt?: string | null, mint: string, status: string, txHash?: string | null, transactions: Array<{ __typename?: 'DistributionTransaction', to: string, invoiceId: string, toName: string, amount: string }> } };

export type GetUsersOfOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersOfOrganizationQuery = { __typename?: 'Query', getUsersOfOrganization: Array<{ __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: AdminRole }> };

export type GetLogHistoryOfOrganizationQueryVariables = Exact<{
  page: Scalars['Int']['input'];
}>;


export type GetLogHistoryOfOrganizationQuery = { __typename?: 'Query', getLogHistoryOfOrganization: Array<{ __typename?: 'UserLog', id: number, organizationName: string, username: string, action: string, date: string }> };

export type GetLogHistoryOfUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
}>;


export type GetLogHistoryOfUserQuery = { __typename?: 'Query', getLogHistoryOfUser: Array<{ __typename?: 'UserLog', id: number, organizationName: string, username: string, action: string, date: string }> };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type ValidateDistributionMutationVariables = Exact<{
  mint: Scalars['Int']['input'];
  distribution: Array<DistributionTransactionInput> | DistributionTransactionInput;
}>;


export type ValidateDistributionMutation = { __typename?: 'Mutation', validateDistribution: { __typename?: 'Distribution', id: number, totalAmount: string, transactions: Array<{ __typename?: 'DistributionTransaction', to: string, amount: string }> } };

export type ApproveDistributionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  secret: Scalars['String']['input'];
}>;


export type ApproveDistributionMutation = { __typename?: 'Mutation', approveDistribution: number };

export type RejectDistributionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RejectDistributionMutation = { __typename?: 'Mutation', rejectDistribution: number };

export type UpdateUsernameMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type UpdateUsernameMutation = { __typename?: 'Mutation', updateUsername: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean } };

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type UpdateEmailMutation = { __typename?: 'Mutation', updateEmail: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean } };

export type UpdatePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean } };

export type ResetMyPasswordMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetMyPasswordMutation = { __typename?: 'Mutation', resetMyPassword: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean } };

export type RequestTwoFactorAuthMutationVariables = Exact<{ [key: string]: never; }>;


export type RequestTwoFactorAuthMutation = { __typename?: 'Mutation', requestTwoFactorAuth: string };

export type SetupTwoFactorAuthMutationVariables = Exact<{
  secret: Scalars['String']['input'];
}>;


export type SetupTwoFactorAuthMutation = { __typename?: 'Mutation', setupTwoFactorAuth: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean } };

export type AddUserMutationVariables = Exact<{
  username: Scalars['String']['input'];
  email: Scalars['String']['input'];
  role: AdminRole;
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean } };

export type UpdateUserRoleMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  role: AdminRole;
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean, role: AdminRole } };

export type ToggleUserStatusMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ToggleUserStatusMutation = { __typename?: 'Mutation', toggleUserStatus: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean } };

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'User', userId: string, username: string, email: string, isActive: boolean } };


export const GetNonceDocument = gql`
    query getNonce {
  getNonce
}
    `;

export function useGetNonceQuery(options?: Omit<Urql.UseQueryArgs<GetNonceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNonceQuery, GetNonceQueryVariables>({ query: GetNonceDocument, ...options });
};
export const GetOrgAndUserDocument = gql`
    query getOrgAndUser {
  getOrganization {
    id
    name
    ethWalletAddress
    btcWalletAddress
    balance {
      credit
      onHold
      mint
      mintId
    }
  }
  getUser {
    userId
    username
    email
    isActive
    role
    has2fa
  }
}
    `;

export function useGetOrgAndUserQuery(options?: Omit<Urql.UseQueryArgs<GetOrgAndUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrgAndUserQuery, GetOrgAndUserQueryVariables>({ query: GetOrgAndUserDocument, ...options });
};
export const GetOrganizationDocument = gql`
    query getOrganization {
  getOrganization {
    id
    name
    ethWalletAddress
    btcWalletAddress
    balance {
      credit
      onHold
      mint
      mintId
    }
  }
}
    `;

export function useGetOrganizationQuery(options?: Omit<Urql.UseQueryArgs<GetOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>({ query: GetOrganizationDocument, ...options });
};
export const GetUserDocument = gql`
    query getUser {
  getUser {
    userId
    username
    email
    isActive
    has2fa
    role
  }
}
    `;

export function useGetUserQuery(options?: Omit<Urql.UseQueryArgs<GetUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({ query: GetUserDocument, ...options });
};
export const GetOtherUserDocument = gql`
    query getOtherUser($userId: String!) {
  getOtherUser(userId: $userId) {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useGetOtherUserQuery(options: Omit<Urql.UseQueryArgs<GetOtherUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOtherUserQuery, GetOtherUserQueryVariables>({ query: GetOtherUserDocument, ...options });
};
export const GetTransactionsHistoryDocument = gql`
    query getTransactionsHistory($page: Int!) {
  getTransactionsHistory(page: $page) {
    id
    type
    creditDelta
    credit
    date
    isProcessed
    txHash
    status
  }
}
    `;

export function useGetTransactionsHistoryQuery(options: Omit<Urql.UseQueryArgs<GetTransactionsHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTransactionsHistoryQuery, GetTransactionsHistoryQueryVariables>({ query: GetTransactionsHistoryDocument, ...options });
};
export const GetDistributionsToApproveDocument = gql`
    query getDistributionsToApprove {
  getDistributionsToApprove {
    id
    totalAmount
    creater
    transactions {
      to
      amount
    }
  }
}
    `;

export function useGetDistributionsToApproveQuery(options?: Omit<Urql.UseQueryArgs<GetDistributionsToApproveQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDistributionsToApproveQuery, GetDistributionsToApproveQueryVariables>({ query: GetDistributionsToApproveDocument, ...options });
};
export const GetDistributionDocument = gql`
    query getDistribution($id: Int!) {
  getDistribution(id: $id) {
    id
    amount
    gas
    totalAmount
    createdBy
    approvedBy
    transactions {
      to
      invoiceId
      toName
      amount
    }
    createdAt
    processedAt
    mint
    status
    txHash
  }
}
    `;

export function useGetDistributionQuery(options: Omit<Urql.UseQueryArgs<GetDistributionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDistributionQuery, GetDistributionQueryVariables>({ query: GetDistributionDocument, ...options });
};
export const GetUsersOfOrganizationDocument = gql`
    query getUsersOfOrganization {
  getUsersOfOrganization {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useGetUsersOfOrganizationQuery(options?: Omit<Urql.UseQueryArgs<GetUsersOfOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUsersOfOrganizationQuery, GetUsersOfOrganizationQueryVariables>({ query: GetUsersOfOrganizationDocument, ...options });
};
export const GetLogHistoryOfOrganizationDocument = gql`
    query getLogHistoryOfOrganization($page: Int!) {
  getLogHistoryOfOrganization(page: $page) {
    id
    organizationName
    username
    action
    date
  }
}
    `;

export function useGetLogHistoryOfOrganizationQuery(options: Omit<Urql.UseQueryArgs<GetLogHistoryOfOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLogHistoryOfOrganizationQuery, GetLogHistoryOfOrganizationQueryVariables>({ query: GetLogHistoryOfOrganizationDocument, ...options });
};
export const GetLogHistoryOfUserDocument = gql`
    query getLogHistoryOfUser($userId: String!, $page: Int!) {
  getLogHistoryOfUser(userId: $userId, page: $page) {
    id
    organizationName
    username
    action
    date
  }
}
    `;

export function useGetLogHistoryOfUserQuery(options: Omit<Urql.UseQueryArgs<GetLogHistoryOfUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLogHistoryOfUserQuery, GetLogHistoryOfUserQueryVariables>({ query: GetLogHistoryOfUserDocument, ...options });
};
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const ValidateDistributionDocument = gql`
    mutation validateDistribution($mint: Int!, $distribution: [DistributionTransactionInput!]!) {
  validateDistribution(mint: $mint, distribution: $distribution) {
    id
    totalAmount
    transactions {
      to
      amount
    }
  }
}
    `;

export function useValidateDistributionMutation() {
  return Urql.useMutation<ValidateDistributionMutation, ValidateDistributionMutationVariables>(ValidateDistributionDocument);
};
export const ApproveDistributionDocument = gql`
    mutation approveDistribution($id: Int!, $secret: String!) {
  approveDistribution(id: $id, secret: $secret)
}
    `;

export function useApproveDistributionMutation() {
  return Urql.useMutation<ApproveDistributionMutation, ApproveDistributionMutationVariables>(ApproveDistributionDocument);
};
export const RejectDistributionDocument = gql`
    mutation rejectDistribution($id: Int!) {
  rejectDistribution(id: $id)
}
    `;

export function useRejectDistributionMutation() {
  return Urql.useMutation<RejectDistributionMutation, RejectDistributionMutationVariables>(RejectDistributionDocument);
};
export const UpdateUsernameDocument = gql`
    mutation updateUsername($username: String!) {
  updateUsername(username: $username) {
    userId
    username
    email
    isActive
  }
}
    `;

export function useUpdateUsernameMutation() {
  return Urql.useMutation<UpdateUsernameMutation, UpdateUsernameMutationVariables>(UpdateUsernameDocument);
};
export const UpdateEmailDocument = gql`
    mutation updateEmail($email: String!) {
  updateEmail(email: $email) {
    userId
    username
    email
    isActive
  }
}
    `;

export function useUpdateEmailMutation() {
  return Urql.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument);
};
export const UpdatePasswordDocument = gql`
    mutation updatePassword($oldPassword: String!, $newPassword: String!) {
  updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    userId
    username
    email
    isActive
  }
}
    `;

export function useUpdatePasswordMutation() {
  return Urql.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument);
};
export const ResetMyPasswordDocument = gql`
    mutation resetMyPassword {
  resetMyPassword {
    userId
    username
    email
    isActive
  }
}
    `;

export function useResetMyPasswordMutation() {
  return Urql.useMutation<ResetMyPasswordMutation, ResetMyPasswordMutationVariables>(ResetMyPasswordDocument);
};
export const RequestTwoFactorAuthDocument = gql`
    mutation requestTwoFactorAuth {
  requestTwoFactorAuth
}
    `;

export function useRequestTwoFactorAuthMutation() {
  return Urql.useMutation<RequestTwoFactorAuthMutation, RequestTwoFactorAuthMutationVariables>(RequestTwoFactorAuthDocument);
};
export const SetupTwoFactorAuthDocument = gql`
    mutation setupTwoFactorAuth($secret: String!) {
  setupTwoFactorAuth(secret: $secret) {
    userId
    username
    email
    isActive
  }
}
    `;

export function useSetupTwoFactorAuthMutation() {
  return Urql.useMutation<SetupTwoFactorAuthMutation, SetupTwoFactorAuthMutationVariables>(SetupTwoFactorAuthDocument);
};
export const AddUserDocument = gql`
    mutation addUser($username: String!, $email: String!, $role: AdminRole!) {
  addUser(username: $username, email: $email, role: $role) {
    userId
    username
    email
    isActive
  }
}
    `;

export function useAddUserMutation() {
  return Urql.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument);
};
export const UpdateUserRoleDocument = gql`
    mutation updateUserRole($userId: String!, $role: AdminRole!) {
  updateUserRole(userId: $userId, role: $role) {
    userId
    username
    email
    isActive
    role
  }
}
    `;

export function useUpdateUserRoleMutation() {
  return Urql.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument);
};
export const ToggleUserStatusDocument = gql`
    mutation toggleUserStatus($userId: String!) {
  toggleUserStatus(userId: $userId) {
    userId
    username
    email
    isActive
  }
}
    `;

export function useToggleUserStatusMutation() {
  return Urql.useMutation<ToggleUserStatusMutation, ToggleUserStatusMutationVariables>(ToggleUserStatusDocument);
};
export const ResetPasswordDocument = gql`
    mutation resetPassword($userId: String!) {
  resetPassword(userId: $userId) {
    userId
    username
    email
    isActive
  }
}
    `;

export function useResetPasswordMutation() {
  return Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument);
};


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AdminRole: AdminRole;
  Balance: ResolverTypeWrapper<Balance>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  DetailedDistribution: ResolverTypeWrapper<DetailedDistribution>;
  Distribution: ResolverTypeWrapper<Distribution>;
  DistributionTransaction: ResolverTypeWrapper<DistributionTransaction>;
  DistributionTransactionInput: DistributionTransactionInput;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Mutation: ResolverTypeWrapper<{}>;
  Organization: ResolverTypeWrapper<Organization>;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Transaction: ResolverTypeWrapper<Transaction>;
  TransactionType: TransactionType;
  User: ResolverTypeWrapper<User>;
  UserLog: ResolverTypeWrapper<UserLog>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Balance: Balance;
  Boolean: Scalars['Boolean']['output'];
  DetailedDistribution: DetailedDistribution;
  Distribution: Distribution;
  DistributionTransaction: DistributionTransaction;
  DistributionTransactionInput: DistributionTransactionInput;
  Int: Scalars['Int']['output'];
  Mutation: {};
  Organization: Organization;
  Query: {};
  String: Scalars['String']['output'];
  Transaction: Transaction;
  User: User;
  UserLog: UserLog;
};

export type BalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Balance'] = ResolversParentTypes['Balance']> = {
  credit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mint?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mintId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onHold?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DetailedDistributionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DetailedDistribution'] = ResolversParentTypes['DetailedDistribution']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gas?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mint?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  processedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['DistributionTransaction']>, ParentType, ContextType>;
  txHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Distribution'] = ResolversParentTypes['Distribution']> = {
  creater?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['DistributionTransaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributionTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DistributionTransaction'] = ResolversParentTypes['DistributionTransaction']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoiceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  toName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationAddUserArgs, 'email' | 'role' | 'username'>>;
  approveDistribution?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationApproveDistributionArgs, 'id' | 'secret'>>;
  logout?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rejectDistribution?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationRejectDistributionArgs, 'id'>>;
  requestTwoFactorAuth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resetMyPassword?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  resetPassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'userId'>>;
  setupTwoFactorAuth?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationSetupTwoFactorAuthArgs, 'secret'>>;
  toggleUserStatus?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationToggleUserStatusArgs, 'userId'>>;
  updateEmail?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateEmailArgs, 'email'>>;
  updatePassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'newPassword' | 'oldPassword'>>;
  updateUserRole?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserRoleArgs, 'role' | 'userId'>>;
  updateUsername?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUsernameArgs, 'username'>>;
  validateDistribution?: Resolver<ResolversTypes['Distribution'], ParentType, ContextType, RequireFields<MutationValidateDistributionArgs, 'distribution' | 'mint'>>;
};

export type OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = {
  balance?: Resolver<Array<ResolversTypes['Balance']>, ParentType, ContextType>;
  btcWalletAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ethWalletAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getDistribution?: Resolver<ResolversTypes['DetailedDistribution'], ParentType, ContextType, RequireFields<QueryGetDistributionArgs, 'id'>>;
  getDistributionsToApprove?: Resolver<Array<ResolversTypes['Distribution']>, ParentType, ContextType>;
  getLogHistoryOfOrganization?: Resolver<Array<ResolversTypes['UserLog']>, ParentType, ContextType, RequireFields<QueryGetLogHistoryOfOrganizationArgs, 'page'>>;
  getLogHistoryOfUser?: Resolver<Array<ResolversTypes['UserLog']>, ParentType, ContextType, RequireFields<QueryGetLogHistoryOfUserArgs, 'page' | 'userId'>>;
  getNonce?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  getOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  getOtherUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryGetOtherUserArgs, 'userId'>>;
  getTransactionsHistory?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType, RequireFields<QueryGetTransactionsHistoryArgs, 'page'>>;
  getUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  getUsersOfOrganization?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
};

export type TransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = {
  credit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creditDelta?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isProcessed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  txHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TransactionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  has2fa?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['AdminRole'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLog'] = ResolversParentTypes['UserLog']> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Balance?: BalanceResolvers<ContextType>;
  DetailedDistribution?: DetailedDistributionResolvers<ContextType>;
  Distribution?: DistributionResolvers<ContextType>;
  DistributionTransaction?: DistributionTransactionResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserLog?: UserLogResolvers<ContextType>;
};

