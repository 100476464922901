import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { Login } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { AuthProvider, RequireAuth } from "./AuthProvider";
import { GqlProvider } from "./GqlProvider";
import { UploadDistribution } from "./pages/UploadDistribution";
import { ResetPassword } from "./pages/ResetPassword";
import { Organization } from "./pages/Organization";
import { Settings } from "./pages/Settings";
import { User } from "./pages/User";
import { Distribution } from "./pages/Distribution";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    path: "/upload",
    element: (
      <RequireAuth>
        <UploadDistribution />
      </RequireAuth>
    ),
  },
  {
    path: "/organization",
    element: (
      <RequireAuth>
        <Organization />
      </RequireAuth>
    ),
  },
  {
    path: "/settings",
    element: (
      <RequireAuth>
        <Settings />
      </RequireAuth>
    ),
  },
  {
    path: "/user/:id",
    element: (
      <RequireAuth>
        <User />
      </RequireAuth>
    ),
  },
  {
    path: "/distribution/:id",
    element: (
      <RequireAuth>
        <Distribution />
      </RequireAuth>
    ),
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <GqlProvider>
        <RouterProvider router={router} />
      </GqlProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
