import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { useAuth } from "../AuthProvider";
import { useEffect, useState } from "react";
import { Toaster } from "../components/ui/toaster";
import { useToast } from "../components/ui/use-toast";

export function ResetPassword() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { token } = useParams();
  const { toast } = useToast();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (token === undefined) {
      navigate("/", { replace: true });
    }
  }, [navigate, token]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        toast({
          title: "Error",
          description: "Passwords do not match",
          variant: "destructive",
        });
        return;
      }
      if (await auth.resetPassword(password, token ?? "")) {
        toast({
          title: "Success",
          description: "Password reset successfully",
        });
        navigate("/", { replace: true });
      } else {
        toast({
          title: "Error",
          description: "Invalid",
          variant: "destructive",
        });
      }
    } catch (e) {
      toast({
        title: "Error",
        description: (e as Error).message,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="w-full h-full lg:grid  lg:grid-cols-2 ">
      <div className="hidden bg-muted lg:block">
        <img
          src={require("../Airdrop.webp")}
          alt="Splash"
          width="1920"
          height="1080"
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Reset password</h1>
          </div>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password">Password</Label>
              </div>
              <Input
                id="password"
                type="password"
                required
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="flex items-center">
                <Label htmlFor="password">Confirm Password</Label>
              </div>
              <Input
                id="password"
                type="password"
                required
                placeholder="Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <Button type="submit" className="w-full" onClick={handleSubmit}>
              Reset password
            </Button>
          </div>
        </div>
        <Toaster />
      </div>
    </div>
  );
}
