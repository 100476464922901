import { useMemo } from "react";
import {
  createClient,
  Provider as GQLProvider,
  cacheExchange,
  fetchExchange,
} from "urql";
import { authExchange } from "@urql/exchange-auth";

import { useAuth } from "./AuthProvider";
import { Auth } from "./auth";

const authApi = new Auth();

export const GqlProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { auth, setAuth } = useAuth();

  const client = useMemo(() => {
    const jwt = auth?.token;
    return createClient({
      url: `${process.env.REACT_APP_API_URL}/graphql`,
      fetchOptions: () => ({
        headers: { Authorization: jwt ? `Bearer ${jwt}` : "" },
      }),
      exchanges: [
        cacheExchange,
        authExchange(async (utils) => ({
          addAuthToOperation(operation) {
            // LOG THE API CALL
            const definition = operation?.query?.definitions[0];
            if (
              definition?.kind === "OperationDefinition" &&
              definition?.name?.kind === "Name"
            ) {
              const { value } = definition.name;
              console.info("API Call: ", value);
            }

            if (!jwt) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${jwt}`,
            });
          },
          willAuthError() {
            var _isJwtStale: boolean = true;
            if (jwt) {
              _isJwtStale = authApi.isJwtStale(jwt);
              console.log("isJwtStale", _isJwtStale);
              if (_isJwtStale === true) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          },
          didAuthError(error) {
            if (error?.toString()?.includes("Unauthorized")) {
              return true;
            } else {
              console.log("error", error);
              return false;
            }
          },
          async refreshAuth() {
            try {
              console.log("refreshing token");
              if (auth?.refreshToken) {
                const res = await authApi.refresh(auth?.refreshToken);
                setAuth(res);
              } else {
                setAuth(null);
              }
            } catch (error) {
              setAuth(null);
            }
          },
        })),
        fetchExchange,
      ],
    });
  }, [auth?.refreshToken, auth?.token, setAuth]);
  return <GQLProvider value={client}>{children}</GQLProvider>;
};
